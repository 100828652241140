import { AppLayoutRegion, MicroAppInfo } from '@amzn/studios-portal-app-types/micro-app';

import { SingleSpaApplicationContext } from '../types/app';

const createRouteString = ({ path, microAppName }: { path: string; microAppName: string }): string =>
  `<route path="${path}"><application name="${microAppName}/micro-app-index"></application></route>`;

const getRegionalMicroAppRoutes = ({ microApps, region }: { microApps: MicroAppInfo[]; region: AppLayoutRegion }) => {
  const appRoutes: string[] = [];

  microApps
    .filter(({ appLayoutRegion }) => appLayoutRegion === region)
    .forEach(({ basePaths, microAppName }) => {
      basePaths.forEach(({ path }) => {
        appRoutes.push(createRouteString({ path, microAppName }));
      });
    });

  return appRoutes.join('');
};

const createSingleSpaLayoutString = (microApps: MicroAppInfo[]): string => {
  const mainRegionApps = getRegionalMicroAppRoutes({ microApps, region: AppLayoutRegion.Main });
  const asideRegionApps = getRegionalMicroAppRoutes({ microApps, region: AppLayoutRegion.Aside });
  const headerRegionApps = getRegionalMicroAppRoutes({ microApps, region: AppLayoutRegion.Header });

  return `
    <single-spa-router>
      <header>
       ${headerRegionApps}
      </header>
      <main>
        <route default></route>
        ${mainRegionApps}
      </main>
      <aside>
        ${asideRegionApps}
      </aside>
    </single-spa-router>`;
};

export const getSingleSpaLayout = ({ applicationContext }: { applicationContext: SingleSpaApplicationContext }): string => {
  const { apps } = applicationContext.appConfigResult.frontend;

  return createSingleSpaLayoutString(apps);
};
