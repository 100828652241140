import { MicroAppInfo } from '@amzn/studios-portal-app-types/micro-app';

import { SystemJsImportTarget, WebpackResultByApp } from '../types';
import { createMicroAppPath } from './app';

export const setWebpackPublicPath = ({ appRegistryDomain, apps }: { appRegistryDomain: string; apps: MicroAppInfo[] }) => {
  const webpackResultByApp: WebpackResultByApp = {};

  return Promise.allSettled(
    apps.map(async ({ microAppName, packageName, accessLevel }) => {
      const microApp = await System.import<SystemJsImportTarget>(microAppName);
      const webpackPublicPathModule = await microApp.get<{ set: (getPath: (version: string) => string) => void }>('./webpack-public-path');
      const override = localStorage.getItem(`import-map-override:${microAppName}`);

      webpackPublicPathModule().set((version: string): string => {
        const webpackResult = { version, baseUrl: '' };
        const overrideBaseUrl = override ? override.replace(/\/remoteEntry.js$/, '/') : undefined;
        webpackResultByApp[microAppName] = webpackResult;

        if (overrideBaseUrl) {
          webpackResult.baseUrl = overrideBaseUrl;
          return overrideBaseUrl;
        }

        const microAppPath = createMicroAppPath({ microAppName, packageName, version, accessLevel });
        const baseUrl = `https://${appRegistryDomain}/${microAppPath}/`;
        webpackResult.baseUrl = baseUrl;
        return baseUrl;
      });
    })
  )
    .then(() => webpackResultByApp)
    .catch(console.error);
};
