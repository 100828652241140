import { Radio } from '@amzn/sitc-frontend/utils/radio';

import { AppState, AppStateEvent } from '../types';

interface PageMetadataUpdateEventPayload {
  productName: string;
  pageName?: string;
  sectionName?: string;
}

export const publishAppStateChange = (appState: AppState) =>
  window.dispatchEvent(
    new CustomEvent<AppStateEvent['detail']>('studios-portal-app:app-state-change', {
      detail: { appState },
    })
  );

const formatPageTitle = ({ productName, pageName, sectionName }: PageMetadataUpdateEventPayload) => {
  let title: string = productName;

  if (pageName) {
    title = `${pageName} | ${title}`;
  }

  if (sectionName) {
    title = `${sectionName} | ${title}`;
  }

  return title;
};

const updatePageTitle = (payload: PageMetadataUpdateEventPayload) => {
  const title = document.head.querySelector('title');

  if (!title) {
    return;
  }

  title.textContent = formatPageTitle(payload);
};

const initPageMetadataCustomEventHandler = () =>
  window.addEventListener('studios-frontend-platform:update-app-title', (event) =>
    updatePageTitle((event as CustomEvent<PageMetadataUpdateEventPayload>).detail)
  );

export const initPageMetadataHandler = (radio: Radio) => {
  const appChannel = radio.getChannel('app');

  if (!appChannel) {
    console.error('app channel not found');
  }

  appChannel?.subscribe<PageMetadataUpdateEventPayload>('page-metadata-update', ({ payload }) => payload && updatePageTitle(payload));
  initPageMetadataCustomEventHandler();
};
