import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { Maybe } from '@amzn/sitc-frontend-types/helper';
import { RumConfig } from '@amzn/sitc-frontend-types/monitoring/rum';
import { MicroAppInfo } from '@amzn/studios-portal-app-types/micro-app';
import { Application } from 'single-spa';

export enum AppState {
  SigningIn = 'SIGNING_IN',
  LoadingPortal = 'LOADING_PORTAL',
  AppInited = 'APP_INITED',
  AppLoadingError = 'APP_LOADING_ERROR',
}

export type AppStateEvent = CustomEvent<{
  appState: AppState;
}>;

export interface SystemJsImportTarget {
  init: (scope: string[]) => void;
  get: <T = unknown>(name: string) => Promise<() => Application & T>;
}

export interface AppConfigResult {
  app: {
    stage: string;
  };
  auth: {
    awsRegion: string;
    cognitoUserPoolClientId: string;
    cognitoUserPoolDomain: string;
    cognitoUserPoolId: string;
  };
  frontend: {
    appRegistryDomain: string;
    appRegistryApiDomain: string;
    apps: MicroAppInfo[];
    userPreferencesEndpoint: string;
  };
  radio: {
    channels: Record<string, { name: string; owner: string }>;
  };
  rum: Maybe<Partial<RumConfig>>;
}

export interface AuthSession {
  idToken: string;
  userGroups: string[];
  userId: string;
}

export type CustomAppPropsEvent = CustomEvent<{
  appName: string;
  customProps: MicroAppInitializationProps;
}>;

export type InitAppFeaturesEvent = CustomEvent<{
  appName: string;
  appFeatures: Record<string, boolean>;
}>;

export interface WebpackResult {
  version: string;
  baseUrl: string;
}

export type WebpackResultByApp = Record<string, WebpackResult>;
