import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import { Maybe } from '@amzn/sitc-frontend-types/helper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface EnvironmentBadgeProps {
  stage: string;
}

const captionByStage: Record<string, string> = {
  personal: 'app.environment_badge.stage.dev',
  dev: 'app.environment_badge.stage.dev',
  beta: 'app.environment_badge.stage.beta',
  gamma: 'app.environment_badge.stage.gamma',
};

const colorByStage: Record<string, Maybe<string>> = {
  personal: backlotLightTheme.palette.extended?.blue[400],
  dev: backlotLightTheme.palette.extended?.blue[400],
  beta: backlotLightTheme.palette.extended?.magenta[400],
  gamma: backlotLightTheme.palette.extended?.bloodOrange[400],
};

export const EnvironmentBadge: React.FC<EnvironmentBadgeProps> = ({ stage }) => {
  const { t } = useTranslation();

  return (
    <Container
      disableGutters
      data-testid="environment-badge"
      sx={{
        px: '0.375rem',
        top: 0,
        left: 0,
        position: 'fixed',
        backgroundColor: colorByStage[stage],
        width: 'fit-content',
        height: '1.25rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Typography variant="caption"> {t(captionByStage[stage])}</Typography>
    </Container>
  );
};
