import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import React, { useState } from 'react';

import { WebpackResult } from '../../types';

interface Props {
  customAppProps: MicroAppInitializationProps;
  webpackResult: WebpackResult;
}

export const AppHint: React.FC<Props> = ({ customAppProps, webpackResult }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          border: '1px solid darkred',
          pointerEvents: 'none',
          zIndex: 9999,
        }}
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          background: 'rgba(139,0,0,0.9)',
          color: 'white',
          minWidth: isOpened ? '500px' : 'auto',
          zIndex: 9999,
        }}>
        <div style={{ display: 'flex', flexDirection: 'row', color: 'white', height: '20px' }}>
          <div role="button" style={{ width: '20px', textAlign: 'center', cursor: 'pointer' }} onClick={() => setIsOpened(!isOpened)}>
            {isOpened ? <>&#9650;</> : <>&#9660;</>}
          </div>
          <div>{webpackResult.baseUrl}</div>
        </div>
        {isOpened && (
          <div style={{ background: 'rgba(139,0,0,0.9)', color: 'white', position: 'absolute', left: 0, right: 0, top: '20px' }}>
            <pre style={{ overflow: 'scroll' }}>{JSON.stringify(customAppProps, null, 2)}</pre>
          </div>
        )}
      </div>
    </>
  );
};
