export const en = {
  'app.loading.caption.signing_in': 'Signing In',
  'app.loading.caption.loading_portal': 'Loading Backlot',
  'app.loading.caption.error': 'Error',
  'app.environment_badge.stage.dev': 'dev',
  'app.environment_badge.stage.beta': 'beta',
  'app.environment_badge.stage.gamma': 'gamma',
  'error.try_reload_message':
    'Please try refreshing the page. If that does not work, you can create a SIM ticket for the Studios Team to follow-up on.',
  'error.action_label': 'Create SIM Ticket',
  'error.load_error.error_message': 'There was an error loading this asset.',
};
