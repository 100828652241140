interface FeatureDetail {
  value: boolean;
  overrideValue?: boolean;
}

type FeatureDetailByKey = Record<string, FeatureDetail>;

type FeatureDetailsByApp = Record<string, FeatureDetailByKey>;

type FeatureByKey = Record<string, boolean>;

type FeaturesByApp = Record<string, FeatureByKey>;

const StorageKey = 'studios-portal-app:micro-app-features';
const FeaturesUpdatedEventKey = 'studios-portal-app:features-updated';
const queryRegex = /^features\.([a-z-]+).([A-Za-z0-9]+)$/;

const featureDetailsByApp: FeatureDetailsByApp = {};
let featureOverridesByApp: FeaturesByApp;

const initFeatureOverridesByApp = (): void => {
  try {
    featureOverridesByApp = JSON.parse(localStorage.getItem(StorageKey)!) || {};
  } catch (e) {
    featureOverridesByApp = {};
  }
};

const updateFeatureOverridesFromQuery = () => {
  const searchParams = new window.URLSearchParams(window.location.search);
  searchParams.forEach((value, param) => {
    const matches = param.match(queryRegex);
    if (matches && (value === '1' || value === '0')) {
      const microAppName = matches[1];
      const featureOverrides: FeatureByKey = featureOverridesByApp[microAppName] || {};
      const feature = matches[2];
      const override = value === '1';
      featureOverrides[feature] = override;
      featureOverridesByApp[microAppName] = featureOverrides;
    }
  });
  localStorage.setItem(StorageKey, JSON.stringify(featureOverridesByApp));
};

export const initFeatureOverrides = (): void => {
  initFeatureOverridesByApp();
  updateFeatureOverridesFromQuery();
};

const removeQueryParam = (param: string) => {
  const url = new window.URL(window.location.href);
  url.searchParams.delete(param);
  window.history.pushState(null, document.title, url.toString());
};

export const updateFeatureOverride = (appName: string, feature: string, value: boolean) => {
  const featureDetailByKey = featureDetailsByApp[appName] || {};
  const featureOverridesByKey = featureOverridesByApp[appName] || {};

  if (value === featureDetailByKey[feature]?.value) {
    featureDetailByKey[feature] = { ...featureDetailByKey[feature], overrideValue: undefined };
    delete featureOverridesByKey[feature];
  } else {
    featureDetailByKey[feature].overrideValue = value;
    featureOverridesByKey[feature] = value;
  }

  featureDetailsByApp[appName] = featureDetailByKey;
  featureOverridesByApp[appName] = featureOverridesByKey;
  localStorage.setItem(StorageKey, JSON.stringify(featureOverridesByApp));
  removeQueryParam(`features.${appName}.${feature}`);
  window.dispatchEvent(new CustomEvent(FeaturesUpdatedEventKey));
};

export const initAppFeatures = (appName: string, appFeatures: FeatureByKey) => {
  const featureOverridesByKey = featureOverridesByApp[appName] || {};
  const featureDetailByKey: FeatureDetailByKey = {};

  Object.entries(appFeatures).forEach(([feature, value]) => {
    if (value === featureOverridesByKey[feature]) {
      featureDetailByKey[feature] = { value, overrideValue: undefined };
      delete featureOverridesByKey[feature];
    } else {
      featureDetailByKey[feature] = { value, overrideValue: featureOverridesByKey[feature] };
    }
  });

  featureDetailsByApp[appName] = featureDetailByKey;
  window.dispatchEvent(new CustomEvent(FeaturesUpdatedEventKey));
};

export const getAppFeatureDetails = (appName: string) => featureDetailsByApp?.[appName] || {};

export const getAppFeatureOverrides = (appName: string) => featureOverridesByApp?.[appName] || {};

export const addFeaturesUpdatedEventListener = (handler: EventListener) => window.addEventListener(FeaturesUpdatedEventKey, handler);

export const removeFeaturesUpdatedEventListener = (handler: EventListener) => window.removeEventListener(FeaturesUpdatedEventKey, handler);
