import { PageLoading } from '@amzn/sitc-frontend/components';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AppState } from '../types';

type AppStateWithCaptions = Exclude<AppState, AppState.AppInited>;

interface LoaderProps {
  appState: AppStateWithCaptions;
}

const captionsByAppState: Record<AppStateWithCaptions, string> = {
  [AppState.SigningIn]: 'app.loading.caption.signing_in',
  [AppState.LoadingPortal]: 'app.loading.caption.loading_portal',
  [AppState.AppLoadingError]: 'app.loading.caption.error',
};

export const AppLoader: React.FC<LoaderProps> = ({ appState }) => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={backlotLightTheme}>
      <CssBaseline />
      <PageLoading caption={t(captionsByAppState[appState])} />
    </ThemeProvider>
  );
};
