import { Error, UnexpectedErrorImage } from '@amzn/sitc-frontend/components';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const PortalAppError: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={backlotLightTheme}>
      <CssBaseline />
      <Error
        actionLabel={t('error.action_label')}
        errorCodeDescription={t('error.load_error.error_message')}
        errorImage={<UnexpectedErrorImage />}
        longDescription={t('error.try_reload_message')}
        onAction={() => window.open('https://issues.amazon.com/issues/create?template=3bc7cd0b-e740-4760-a584-a35eef183e62')}
        page
      />
    </ThemeProvider>
  );
};
