import { CommonUserPreferences, UserPreferencesEntry } from '@amzn/sitc-frontend/types';
import { PreferencesFetcher } from '@amzn/sitc-frontend/utils';
import { Channel, Radio } from '@amzn/sitc-frontend/utils/radio';

import { AppId } from '../configs/app';
import { DefaultPreferences } from '../configs/user-preferences';
import { AppConfigResult, AuthSession } from '../types';

interface UserPreferencesHandlerProps {
  fetcher: PreferencesFetcher<CommonUserPreferences>;
  channel?: Channel;
}

export class UserPreferencesHandler {
  private userPreferences?: UserPreferencesEntry<CommonUserPreferences>;

  constructor(private props: UserPreferencesHandlerProps) {
    if (!props.channel) {
      console.warn('User preferences channel not found. Change event handlers not inited.');
    } else {
      this.initChannelEventHandlers();
    }
  }

  async fetch() {
    const { fetcher } = this.props;
    const userPreferences = await fetcher.getPreferences();

    this.userPreferences = userPreferences;
  }

  getCurrentPreferences() {
    return this.userPreferences;
  }

  private initChannelEventHandlers() {
    this.initUpdateHandler();
    this.initPatchHandler();
  }

  private handleUserPreferencesUpdated = (userPreferences: UserPreferencesEntry<CommonUserPreferences>) => {
    const { channel } = this.props;

    this.userPreferences = userPreferences;
    channel?.publish('updated', { payload: userPreferences });
  };

  private initUpdateHandler() {
    const { channel, fetcher } = this.props;
    channel?.subscribe<{ preferences: CommonUserPreferences }>('update', (event) => {
      const { preferences } = event.payload || {};

      if (!preferences) {
        return;
      }

      fetcher
        .updatePreferences(preferences)
        .then(this.handleUserPreferencesUpdated)
        .catch((error) => console.warn('Error updating preferences', error));
    });
  }

  private initPatchHandler() {
    const { channel, fetcher } = this.props;
    channel?.subscribe<{ preferences: Partial<CommonUserPreferences> }>('patch', (event) => {
      const { preferences } = event.payload || {};

      if (!preferences) {
        return;
      }

      fetcher
        .patchPreferences(preferences)
        .then(this.handleUserPreferencesUpdated)
        .catch((error) => console.warn('Error patching preferences', error));
    });
  }
}

export const initUserPreferencesHandler = async ({
  appConfigResult,
  authSession,
  radio,
}: {
  appConfigResult: AppConfigResult;
  authSession: AuthSession;
  radio: Radio;
}) => {
  const { userPreferencesEndpoint } = appConfigResult.frontend;
  const preferencesFetcher = new PreferencesFetcher<CommonUserPreferences>({
    appId: AppId,
    authorization: `Bearer ${authSession.idToken}`,
    endpoint: userPreferencesEndpoint,
    userId: authSession.userId,
  });
  const channel = radio.getChannel('user-preferences');
  const userPreferencesHandler = new UserPreferencesHandler({
    channel,
    fetcher: preferencesFetcher,
  });

  await userPreferencesHandler.fetch();

  return userPreferencesHandler;
};

export const patchUserPreferencesWithDefaults = (
  authSession: AuthSession,
  preferencesEntry?: UserPreferencesEntry<Partial<CommonUserPreferences>>
): UserPreferencesEntry<CommonUserPreferences> => ({
  userId: authSession.userId,
  preferences: { ...DefaultPreferences, ...preferencesEntry?.preferences },
});
