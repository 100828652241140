import React, { Suspense, useState } from 'react';

import { AppConfigResult, WebpackResultByApp } from '../../types';

export interface DevInfoProps {
  appConfigResult: AppConfigResult;
  webpackResultByApp: WebpackResultByApp;
}

const Apps = React.lazy(() => import('./Apps'));

export const DevInfo: React.FC<DevInfoProps> = ({ appConfigResult, webpackResultByApp }) => {
  const [isOpened, setIsOpened] = useState(false);

  if (!isOpened) {
    return (
      <div
        aria-label="Open dev info"
        role="button"
        style={{ width: '50px', height: '50px' }}
        onClick={(event) => {
          if (event.shiftKey) {
            setIsOpened(true);
          }
        }}
      />
    );
  }

  return (
    <Suspense fallback="">
      <div
        style={{
          width: '500px',
          maxWidth: '50vw',
          height: '100vh',
          padding: '10px',
          background: 'rgba(0,0,0,0.7)',
          fontSize: '14px',
          color: 'white',
          boxSizing: 'border-box',
        }}>
        <Apps appConfigResult={appConfigResult} webpackResultByApp={webpackResultByApp} onClose={() => setIsOpened(false)} />
      </div>
    </Suspense>
  );
};
