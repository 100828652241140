import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppHint } from '../components/DevInfo/AppHint';
import { DevInfo, DevInfoProps } from '../components/DevInfo/DevInfo';
import { CustomAppPropsEvent, InitAppFeaturesEvent, WebpackResultByApp } from '../types';
import { addPreferencesUpdatedEventListener, DisplayAppHint, getDevInfoPreferences } from './dev-info-preferences';
import { initAppFeatures, initFeatureOverrides } from './features';

const customPropsByApp: Record<string, MicroAppInitializationProps> = {};

const renderAppHints = ({ webpackResultByApp }: { webpackResultByApp: WebpackResultByApp }) => {
  const { displayAppHint } = getDevInfoPreferences();

  document.querySelectorAll('[id^="single-spa-application:"]').forEach((node: Element) => {
    const appNode = node as HTMLElement;
    const appName = appNode.id.match(/:([^/]+)/)?.[1];

    if (!appName) {
      return;
    }

    const overrideUrl = localStorage.getItem(`import-map-override:${appName}`);
    const currentAppHintNode = appNode.querySelector('.app-hint');

    if (displayAppHint === DisplayAppHint.None || (!overrideUrl && displayAppHint === DisplayAppHint.Override)) {
      currentAppHintNode?.remove();
      return;
    }

    if (currentAppHintNode) {
      return;
    }

    const hintContainer = document.createElement('div');
    hintContainer.classList.add('app-hint');
    appNode.style.position = 'relative';
    appNode.appendChild(hintContainer);

    const root = createRoot(hintContainer);
    const appHintElement = React.createElement(AppHint, {
      customAppProps: customPropsByApp[appName],
      webpackResult: webpackResultByApp[appName],
    });
    root.render(React.createElement(React.StrictMode, {}, appHintElement));
  });
};

const initAppHint = (props: { webpackResultByApp: WebpackResultByApp }) => {
  window.addEventListener('studios-portal-app:custom-app-props', (event) => {
    const customEvent = event as CustomAppPropsEvent;
    const { appName, customProps } = customEvent.detail;
    customPropsByApp[appName] = customProps;
  });

  const handleAppChange = () => {
    renderAppHints(props);
  };

  window.addEventListener('single-spa:app-change', handleAppChange);
  addPreferencesUpdatedEventListener(handleAppChange);
};

const initFeatures = () => {
  initFeatureOverrides();

  window.addEventListener('studios-frontend-platform:init-app-features', (event) => {
    const customEvent = event as InitAppFeaturesEvent;
    const { appName, appFeatures } = customEvent.detail;
    initAppFeatures(appName, appFeatures);
  });
};

export const initDevInfo = (container: HTMLElement, props: DevInfoProps) => {
  const root = createRoot(container);
  root.render(React.createElement(React.StrictMode, {}, React.createElement(DevInfo, props)));
  initAppHint(props);
  initFeatures();
};
