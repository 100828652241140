/// <reference types="pendo-io-browser" />
import { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';

import { AuthSession } from '../types';

declare global {
  interface Window {
    pendo: MaybeUndefined<pendo.Pendo>;
  }
}

export const initUserAnalytics = async (authSession: AuthSession, appId: string, currentTry = 1): Promise<void> => {
  try {
    const pendo = window?.pendo || undefined;
    if (!pendo) {
      if (currentTry <= 5) {
        // Retry with exponential backoff
        setTimeout(() => {
          // eslint-disable-next-line no-void
          void initUserAnalytics(authSession, appId, currentTry + 1);
        }, 2 ** currentTry * 100);
        return;
      }
      return;
    }

    const pendoTokenResponse = await fetch('/edge/get-pendo-token', {
      headers: {
        Authorization: `Bearer ${authSession.idToken}`,
        'x-fp-app-id': appId,
      },
    });
    const pendoTokenJson = (await pendoTokenResponse.json()) as { jwt: string; signingKeyName: string };

    // pendo.InitOptions type only has types for non jwt implementations
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    pendo.initialize(pendoTokenJson);
  } catch (error) {
    // Allow application to continue even if analytics failed to initialize
    console.error(error);
  }
};
