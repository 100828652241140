import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { createRumClient } from '@amzn/sitc-frontend/utils';
import { backlotLightTheme } from '@amzn/sitc-frontend-theme';
import { MicroAppInfo } from '@amzn/studios-portal-app-types/micro-app';
import { Amplify } from '@aws-amplify/core';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppLoader } from './components/AppLoader';
import { PortalAppError } from './components/PortalAppError';
import { AppId } from './configs/app';
import { AppConfigResult, AppState, AppStateEvent } from './types';
import { ApplicationContext, SingleSpaApplicationContext, UninitedApplicationContext } from './types/app';
import { fetchAppConfig, fetchAppRegistryCookies, formatAmplifyConfig, formatAuthSession } from './utils/app';
import { publishAppStateChange } from './utils/app-state';
import { initAuth } from './utils/auth';
import { getAppFeatureOverrides } from './utils/features';
import { getBasePath } from './utils/micro-apps';
import { initUserAnalytics } from './utils/user-analytics';
import { patchUserPreferencesWithDefaults } from './utils/user-preferences';

const initAppTheme = () => {
  document.body.style.backgroundColor = backlotLightTheme.palette.background.default;
};

const initAppLoader = () => {
  const loaderRoot = createRoot(document.getElementById('loader')!);
  window.addEventListener('studios-portal-app:app-state-change', (event) => {
    const customEvent = event as AppStateEvent;
    const { appState } = customEvent.detail;

    if (appState === AppState.AppInited) {
      loaderRoot.unmount();
    } else if (appState === AppState.AppLoadingError) {
      loaderRoot.unmount();
      const errorRoot = createRoot(document.getElementById('error')!);
      errorRoot.render(React.createElement(React.StrictMode, {}, React.createElement(PortalAppError)));
    } else {
      loaderRoot.render(React.createElement(React.StrictMode, {}, React.createElement(AppLoader, { appState })));
    }
  });
};

const initCloudWatchRum = (appConfigResult: AppConfigResult) => {
  const { rum: rumConfig } = appConfigResult;

  if (!rumConfig) {
    console.warn('CloudWatch RUM config not found');
    return;
  }

  try {
    createRumClient(rumConfig);
  } catch (error) {
    console.warn('CloudWatch RUM initialization encountered an error:', error);
  }
};

export const initApp = async (): Promise<
  ({ isAppInited: false } & UninitedApplicationContext) | ({ isAppInited: true } & ApplicationContext)
> => {
  initAppTheme();
  initAppLoader();

  const appConfigResult = await fetchAppConfig();
  const amplifyConfig = formatAmplifyConfig(appConfigResult);

  initCloudWatchRum(appConfigResult);
  Amplify.configure(amplifyConfig);

  const { cognitoUserSession, hasRedirected } = await initAuth();
  if (!cognitoUserSession || hasRedirected) {
    return { isAppInited: false, appConfigResult, authSession: undefined };
  }

  const authSession = formatAuthSession(cognitoUserSession);

  // eslint-disable-next-line no-void
  void initUserAnalytics(authSession, AppId);

  publishAppStateChange(AppState.LoadingPortal);
  await fetchAppRegistryCookies(appConfigResult, authSession);

  return { isAppInited: true, appConfigResult, authSession };
};

export const getCustomAppProps = (
  importName: string,
  { appConfigResult, authSession, radioController, userPreferencesHandler, webpackResultByApp }: SingleSpaApplicationContext
): MicroAppInitializationProps => {
  const hasMatchingName = (app: MicroAppInfo) => importName === `${app.microAppName}/micro-app-index`;

  const stage = appConfigResult?.app.stage === 'personal' ? 'dev' : appConfigResult?.app.stage;
  const enabledMicroApps = appConfigResult?.frontend.apps ?? [];
  const microApp = enabledMicroApps.find(hasMatchingName);
  const { basePaths, microAppName = '' } = microApp ?? ({} as MicroAppInfo);

  const microAppVersion = webpackResultByApp[microAppName]?.version || '0.0.0';
  const basePath = getBasePath(basePaths);
  const featureOverrides = getAppFeatureOverrides(microAppName);
  const radio = radioController.getRadio(microAppName);
  const userPreferences = patchUserPreferencesWithDefaults(authSession, userPreferencesHandler?.getCurrentPreferences());

  const customProps = {
    appId: AppId,
    authSession,
    basePath,
    featureOverrides,
    microAppName,
    microAppVersion,
    radio,
    stage,
    userPreferences,
  };

  return customProps;
};
