// eslint-disable-next-line import/no-extraneous-dependencies
import 'systemjs-webpack-interop/auto-public-path/3';
import './i18n';

import { MicroAppInitializationProps } from '@amzn/sitc-frontend/types';
import { RadioController } from '@amzn/sitc-frontend/utils/radio';
import { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';
import cloneDeep from 'lodash/cloneDeep';
import {
  addErrorHandler,
  AppError,
  Application,
  AppProps,
  getAppStatus,
  LOAD_ERROR,
  registerApplication,
  SKIP_BECAUSE_BROKEN,
  start,
} from 'single-spa';
import { constructApplications, constructLayoutEngine, constructRoutes } from 'single-spa-layout';

import { getCustomAppProps, initApp } from './app';
import { AppState, CustomAppPropsEvent, SystemJsImportTarget } from './types';
import { SingleSpaApplicationContext } from './types/app';
import { initPageMetadataHandler, publishAppStateChange } from './utils/app-state';
import { initDevInfo } from './utils/dev-info';
import { initEnvironmentBadge } from './utils/environment-badge';
import { getSingleSpaLayout } from './utils/single-spa';
import { injectAppsImportMap, setupSystemJsLoader } from './utils/systemjs';
import { initUserPreferencesHandler, UserPreferencesHandler } from './utils/user-preferences';
import { setWebpackPublicPath } from './utils/webpack';

const loadApp = async ({ name }: AppProps, shareScope: string[]): Promise<Application> => {
  const [packageName, moduleName] = name.split('/');
  const app = await System.import<SystemJsImportTarget>(packageName);
  app.init(shareScope);
  const module = await app.get(`./${moduleName}`);

  return module();
};

const errorHandler = (error: AppError) => {
  const appName = error.appOrParcelName;
  const appStatus = getAppStatus(appName);

  if (appStatus === LOAD_ERROR || appStatus === SKIP_BECAUSE_BROKEN) {
    publishAppStateChange(AppState.AppLoadingError);
    System.delete(System.resolve(error.appOrParcelName));
  }
};

const registerApplicationWithCustomProps = (
  application: ReturnType<typeof constructApplications>[number],
  applicationContext: SingleSpaApplicationContext
): void => {
  registerApplication({
    ...application,
    customProps: (name): MicroAppInitializationProps => {
      const customProps = getCustomAppProps(name, applicationContext);
      window.dispatchEvent(
        new CustomEvent<CustomAppPropsEvent['detail']>('studios-portal-app:custom-app-props', {
          detail: { appName: customProps.microAppName, customProps: cloneDeep(customProps) },
        })
      );
      return customProps;
    },
  });
};

const initSingleSpa = (applicationContext: SingleSpaApplicationContext) => {
  const shareScope: string[] = [];
  const singleSpaLayout = getSingleSpaLayout({ applicationContext });
  const routes = constructRoutes(singleSpaLayout);
  const applications = constructApplications({
    routes,
    loadApp: (appProps) => loadApp(appProps, shareScope),
  });
  const layoutEngine = constructLayoutEngine({ routes, applications });

  addErrorHandler(errorHandler);
  applications.forEach((application) => registerApplicationWithCustomProps(application, applicationContext));
  layoutEngine.activate();
  publishAppStateChange(AppState.AppInited);
  start();
};

initApp()
  .then(async ({ isAppInited, appConfigResult, authSession }) => {
    if (!isAppInited) {
      return;
    }

    const { appRegistryDomain, apps } = appConfigResult.frontend;

    setupSystemJsLoader();
    await injectAppsImportMap(appRegistryDomain, apps);
    const webpackResultByApp = (await setWebpackPublicPath({ appRegistryDomain, apps })) || {};

    const { stage } = appConfigResult.app;

    if (stage !== 'prod') {
      initEnvironmentBadge(document.getElementById('environment-badge')!, { stage });
    }

    try {
      initDevInfo(document.getElementById('dev-info')!, { appConfigResult, webpackResultByApp });
    } catch (error) {
      console.error(error);
    }

    const applicationContext = { appConfigResult, authSession };
    const radioController = new RadioController({ channelConfigs: Object.values(appConfigResult.radio.channels) });
    const radio = radioController.getRadio('app');
    let userPreferencesHandler: MaybeUndefined<UserPreferencesHandler>;

    try {
      userPreferencesHandler = await initUserPreferencesHandler({ ...applicationContext, radio });
    } catch (error) {
      console.error(error);
    }

    initPageMetadataHandler(radio);
    initSingleSpa({ ...applicationContext, radioController, userPreferencesHandler, webpackResultByApp });
  })
  .catch(console.error);
