import { MicroAppRegistryInfo } from '@amzn/studios-portal-app-types/micro-app';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { AppId } from '../configs/app';
import { AppConfigResult, AuthSession } from '../types';

const { hostname } = window.location;
const isLocal = hostname.includes('localhost');

export const fetchAppConfig = async (): Promise<AppConfigResult> => {
  const response = await fetch('/configs.json');
  const result: AppConfigResult = await response.json();

  // TODO: remove when deprecating backlot a2z.com domain and setting these domains from app config
  // https://originalsaccess.atlassian.net/browse/CLOUD-22270
  if (!isLocal) {
    result.frontend.appRegistryDomain = window.location.hostname;
    result.frontend.appRegistryApiDomain = window.location.hostname;
  }

  return result;
};

export const formatAmplifyConfig = (appConfigResult: AppConfigResult) => {
  const { awsRegion, cognitoUserPoolId, cognitoUserPoolClientId, cognitoUserPoolDomain } = appConfigResult.auth;
  const redirectUrl = window.location.origin;

  return {
    aws_project_region: awsRegion,
    Auth: {
      region: awsRegion,
      userPoolId: cognitoUserPoolId,
      userPoolWebClientId: cognitoUserPoolClientId,
      mandatorySignIn: true,
      oauth: {
        domain: cognitoUserPoolDomain,
        scope: ['openid'],
        responseType: 'code',
        redirectSignIn: redirectUrl,
        redirectSignOut: redirectUrl,
      },
    },
  };
};

export const formatAuthSession = (cognitoUserSession: CognitoUserSession): AuthSession => {
  const idToken = cognitoUserSession.getIdToken();
  const { payload: idTokenPayload } = idToken;

  return {
    idToken: idToken.getJwtToken(),
    userGroups: JSON.parse(idTokenPayload['custom:groups'] as string),
    userId: idTokenPayload.identities[0].userId,
  };
};

export const createMicroAppPath = (app: MicroAppRegistryInfo) => {
  const segments = app.packageName.split('/');
  // contains org (e.g. @amzn/) ?
  if (segments.length > 1) {
    const orgName = encodeURIComponent(segments.shift() || '');
    const packageName = segments[segments.length - 1];
    return `apps/${app.accessLevel}/${orgName}/${packageName}/${app.version ?? '*'}/assets`;
  }
  return `apps/${app.accessLevel}/${segments[0]}/${app.version ?? '*'}/assets`;
};

export const fetchAppRegistryCookies = async ({ frontend }: AppConfigResult, { idToken }: AuthSession): Promise<void> => {
  const { appRegistryDomain, appRegistryApiDomain } = frontend;
  const appRegistryCookiesUrl = new URL(`https://${appRegistryApiDomain}/api/app-registry-cookies`);

  if (hostname !== 'localhost') {
    appRegistryCookiesUrl.searchParams.set('domain', appRegistryDomain);
  }

  await fetch(appRegistryCookiesUrl.toString(), {
    headers: {
      authorization: `Bearer ${idToken}`,
      'x-fp-app-id': AppId,
    },
    credentials: 'include',
  });
};
