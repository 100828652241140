import { MicroAppInfo } from '@amzn/studios-portal-app-types/micro-app';

import { createMicroAppPath } from './app';

declare const System: {
  constructor: {
    prototype: {
      createScript: (url: string) => HTMLScriptElement;
    };
  };
  prepareImport: (doProcessScripts: boolean) => Promise<unknown>;
};

export const setupSystemJsLoader = () => {
  const { createScript } = System.constructor.prototype;
  System.constructor.prototype.createScript = function createCrossOriginScript(url: string) {
    const scriptElement: ReturnType<typeof createScript> = createScript.call(this, url);
    scriptElement.removeAttribute('crossorigin');
    return scriptElement;
  };
};

export const injectAppsImportMap = async (appRegistryDomain: string, microApps: MicroAppInfo[]) => {
  const importMap: Record<string, string> = {};

  microApps.forEach((microApp) => {
    const { microAppName } = microApp;
    const microAppPath = createMicroAppPath(microApp);
    const override = localStorage.getItem(`import-map-override:${microAppName}`);
    importMap[microAppName] = override || `https://${appRegistryDomain}/${microAppPath}/remoteEntry.js`;
  });

  const scriptNode = document.createElement('script');
  scriptNode.setAttribute('type', 'systemjs-importmap');
  scriptNode.setAttribute('id', 'import-apps');
  scriptNode.innerHTML = JSON.stringify({
    imports: importMap,
  });
  document.head.appendChild(scriptNode);
  await System.prepareImport(true);
};
