import { MaybeUndefined } from '@amzn/sitc-frontend-types/helper';
import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { AppState } from '../types';
import { publishAppStateChange } from './app-state';

const SignedInRedirectUrlKey = 'studios-portal-app:signed-in-redirect-url';

const setRedirectUrl = () =>
  sessionStorage.setItem(SignedInRedirectUrlKey, sessionStorage.getItem(SignedInRedirectUrlKey) || window.location.href);

const signIn = async () => {
  publishAppStateChange(AppState.SigningIn);
  setRedirectUrl();
  await Auth.federatedSignIn({ customProvider: 'AmazonFederate' });
};

const initCognitoUserSession = (): Promise<MaybeUndefined<CognitoUserSession>> =>
  Auth.currentSession().catch(async () => {
    await signIn();
    return undefined;
  });

const handleSignInRedirect = (): boolean => {
  const signedInRedirectUrl = sessionStorage.getItem(SignedInRedirectUrlKey);
  if (!signedInRedirectUrl) {
    return false;
  }

  sessionStorage.removeItem(SignedInRedirectUrlKey);

  if (window.location.href === signedInRedirectUrl) {
    return false;
  }

  window.location.href = signedInRedirectUrl;
  return true;
};

const handleSignOutRedirect = () => {
  Hub.listen('auth', ({ payload }) => {
    const { event } = payload;
    if (event === 'oAuthSignOut') {
      setRedirectUrl();
    }
  });
};

export const initAuth = async (): Promise<{ cognitoUserSession?: CognitoUserSession; hasRedirected: boolean }> => {
  handleSignOutRedirect();

  const cognitoUserSession = await initCognitoUserSession();
  if (!cognitoUserSession) {
    return { hasRedirected: false };
  }

  const hasRedirected = handleSignInRedirect();
  if (hasRedirected) {
    return { hasRedirected: true };
  }

  return { cognitoUserSession, hasRedirected: false };
};
