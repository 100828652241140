import defaultsDeep from 'lodash/defaultsDeep';

interface DevInfoPreferences {
  displayAppHint: DisplayAppHint;
}

export const enum DisplayAppHint {
  All = 'all',
  Override = 'override',
  None = 'none',
}

const DefaultDevInfoPreferences: DevInfoPreferences = {
  displayAppHint: DisplayAppHint.Override,
};

const StorageKey = 'studios-portal-app:dev-info-preferences';
const PreferencesUpdatedEventKey = 'studios-portal-app:dev-info-preferences-updated';

let preferences: DevInfoPreferences;

const initPreferences = (): void => {
  const serializedPreferences = localStorage.getItem(StorageKey);

  try {
    preferences = JSON.parse(serializedPreferences!) || {};
  } catch (e) {
    preferences = DefaultDevInfoPreferences;
  }

  defaultsDeep(preferences, DefaultDevInfoPreferences);
};

export const updateDevInfoPreferences = (newPreferences: DevInfoPreferences) => {
  preferences = newPreferences;
  localStorage.setItem(StorageKey, JSON.stringify(preferences));
  window.dispatchEvent(new CustomEvent(PreferencesUpdatedEventKey));
};

export const getDevInfoPreferences = (reinit = false) => {
  if (!preferences || reinit) {
    initPreferences();
  }
  return preferences;
};

export const addPreferencesUpdatedEventListener = (handler: EventListener) => window.addEventListener(PreferencesUpdatedEventKey, handler);

export const removePreferencesUpdatedEventListener = (handler: EventListener) =>
  window.removeEventListener(PreferencesUpdatedEventKey, handler);
