import { MicroAppInfo } from '@amzn/studios-portal-app-types/micro-app';

export const getBasePath = (basePaths?: MicroAppInfo['basePaths']) => {
  if (!basePaths?.length) {
    return '';
  }

  const basePath = basePaths.find(({ path, exact = false }) => {
    if (!exact) {
      return window.location.pathname.startsWith(path);
    }

    return path === window.location.pathname;
  });

  return basePath?.path || '';
};

export const getMicroAppByName = (apps: MicroAppInfo[], microAppName: string) => apps.find((app) => app.microAppName === microAppName);
